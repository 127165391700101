import $ from 'sbf-design-system/js/jquery';
import banner from 'sbf-design-system-component/banners/banner';

const NewsletterForm = {
  selector: '#newsletter-form',
  button: '.button',
  url: '/ajax/email/process-signup',
  nameInput: '#first_name',
  emailInput: '#email_address'
};

NewsletterForm.init = function () {
  const form = this;

  $(this.selector).on('submit', function (e) {
    e.preventDefault();
    const $this = $(this);
    const emailAddress = $(form.emailInput).val();
    let isInvalid = false;

    if ($(form.nameInput).val() === '') {
      banner.showError('Please enter your first name', false, 4000);
      isInvalid = true;
    }
    if (emailAddress.indexOf('@') === -1) {
      banner.showError('Please enter a valid email address', false, 4000);
      isInvalid = true;
    }

    if (!isInvalid) {
      const data = $this.serialize();

      $this.find(form.button).addClass('button--disabled');
      $.ajax({
        url: form.url,
        type: 'POST',
        data,
        complete: function (e) {
          if (e) {
            const data = $.parseJSON(e.responseText);
            if (data) {
              if (data.success) {
                $this.after('<p class="lead italics strong">Thanks, you’ll be hearing from us soon!</p>');
                $this.hide();
              } else {
                console.warn('SOMETHING IS DREADFULLY WRONG');
                banner.showError('Oops! Something went wrong. Please try again.', false, 4000);
              }
            }
          }
          $this.find(form.button).removeClass('button--disabled');
        }
      });
    }
  });
};

export default NewsletterForm;
