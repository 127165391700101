import $ from 'sbf-design-system/js/jquery';
import Overlay from 'sbf-design-system-component/overlays/overlay';

const VideoOverlay = {
  selector: '[data-video-overlay]'
};

VideoOverlay.init = function () {
  const self = this;
  this.triggers = document.querySelectorAll(this.selector);

  for (let i = this.triggers.length - 1; i >= 0; i--) {
    this.triggers[i].addEventListener('click', function (e) {
      e.preventDefault();
      self._showOverlay(this);
    });
  }
};

VideoOverlay._showOverlay = function (link) {
  if (!this.overlay) {
    const $overlay = $('<div id="video-overlay"></div>');
    $('body').append($overlay);
    this.overlay = new Overlay($overlay, { full_screen: true });

    this.overlay.$el.on('overlay.afterHide', function () {
      this.overlay.$el.find('.responsive-video').remove();
    }.bind(this));
  }

  this.overlay.$el.html(
    `<div class="video-container"><div class="responsive-video"><iframe width="560" height="315" src="${
      link.getAttribute('href')
    }?rel=0" frameborder="0" allowfullscreen></iframe></div></div>`
  );
  this.overlay.show();
};

export default VideoOverlay;
