'use strict';

import banner from 'sbf-design-system-component/banners/banner'; // eslint-disable-line no-unused-vars
import videoOverlay from 'sbf-design-system-component/overlays/video_overlay';
import newsletterForm from 'sbf-design-system-component/forms/newsletter';
import 'lib/jquery.hoverdir';

// hover functionality
if (window.innerWidth > 775) {
  $('.js-fancy-overlay').hoverdir({
    hoverElem: '.hoverelem',
    speed: 100
  });
}

// CMS - Stop a href from returning on click in CMS edit mode
if (window.sbf && window.sbf.ce) {
  $('.js-stop-prop').on('click', function () {
    return false;
  });
}

// newsletter form
newsletterForm.init();

// animating map dots
const mapPos = $('#map-section')[0].offsetTop;

function animateMap () {
  const windowPosition = (window.pageYOffset || document.documentElement.scrollTop) - (document.documentElement.clientTop || 0);

  if (windowPosition > mapPos - window.outerHeight / 2) {
    $('#js-map-dots').addClass('animate');
  } else {
    window.requestAnimationFrame(animateMap);
  }
}
window.requestAnimationFrame(animateMap);

// video overlay (obviously)
videoOverlay.init();
